@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
html, body {
  font-family: 'Open Sans', sans-serif;
  color: #4f4f4f;
}
.page-container{
  display: grid;
  align-items: center;
  justify-items: center;
}

.header-title-text{
  padding: 2rem;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
}

.prices-grid-container{
  display: grid;
  width: 100%;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}

.tooltip-label{
  font-size: 3rem;
  padding: 2rem 0 0;
  transition: color .2s;
}

.tooltip-date{
  font-size: 1rem;
  padding: 0 0 2rem;
  transition: color .2s;
}

.last-updated-label{
  font-size: 0.75rem;
  padding: 2rem 0 0;
}

.chart-container{
  width: 60%;
}

.chart-container:hover{
  cursor: crosshair;
}

.spot-label{
  color: #E44722;
}

.eagle-label{
  color: #64932B;
}

.generic-label{
  color: #29889D;
}

input[type='checkbox']{
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .header-title-text{
    padding: 1rem;
    font-size: 1.5rem;
  }

  .prices-grid-container{
    justify-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .chart-container{
    width: 100%;
  }

  .tooltip-label{
    font-size: 2rem;
    padding: 1rem 0 0;
  }

  .tooltip-date{
    font-size: 0.75rem;
    padding: 0 0 1rem;
  }
}

